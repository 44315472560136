import React, { FC, ReactElement } from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby';
import * as styles from './Pages.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '~components/Layout';
import StickyBox from "react-sticky-box";
import SocialShare from '~components/SocialShare';
import Moment from 'react-moment';
import { BLOCKS } from '@contentful/rich-text-types';

const BlogTemplate: FC<unknown> = (params): ReactElement => {
  const { article: articles, allContentfulBlogPage } = useStaticQuery(query);
  //TODO: filters not working
  const article = articles.edges.find(edge => edge.node.id === params.pageContext.id).node;
  const image = getImage(article.image)
  const instagramLink = allContentfulBlogPage.nodes[0].instagramLink;

  const isBrowser = typeof window !== "undefined"
  const locationHref = isBrowser && window.location.href;

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        const reference = article.content.references.find(item => item.contentful_id === node.data.target.sys.id)
        const image = getImage(reference.gatsbyImageData)
        return image && (
          <GatsbyImage
            alt={``}
            image={image}
            className={styles.image}
          />
        );
      },
    },
  };

  const imageBlock = (
    image && (
      <GatsbyImage
        alt={``}
        image={image}
        className={styles.image}
      />
    )
  );

  const desktopTitleBlock = (
    <div className={`${styles.title} ${styles.desktop}`}>
      <h1>
        {!article.richTitle.raw && article.title}
        {article.richTitle.raw && documentToReactComponents(JSON.parse(article.richTitle.raw))}
      </h1>
      {/*<div className={styles.category}>*/}
      {/*  {article.category[0].title}*/}
      {/*  •*/}
      {/*  <Moment format="DD MMM YYYY">*/}
      {/*    {article.published}*/}
      {/*  </Moment>*/}
      {/*</div>*/}
    </div>
  );

  const mobileTitleBlock = (
    <div className={`${styles.title} ${styles.mobile}`}>
      <h1>{article.title}</h1>
    </div>
  )

  const desktopShareBlock = (
    <StickyBox offsetTop={120} offsetBottom={120}>
      <div className={`${styles.share} ${styles.desktop}`}>
        <SocialShare title={article.title} link={locationHref} instagramLink={instagramLink} />
      </div>
    </StickyBox>
  )

  const mobileShareBlock = (
    <div className={`${styles.share} ${styles.mobile}`}>
      <SocialShare title={article.title} link={locationHref} instagramLink={instagramLink} />
    </div>
  )

  const mobileCategory = (
    <div className={`${styles.mobile} ${styles.category}`}>
      {/*<div className={styles.categoryCol}>*/}
      {/*  <div className={styles.categoryTitle}>Category</div>*/}
      {/*  <div>{article.category[0].title}</div>*/}
      {/*</div>*/}
      {/*<div className={styles.categoryCol}>*/}
      {/*  <div className={styles.categoryTitle}>Published</div>*/}
      {/*  <div>*/}
      {/*    <Moment format="DD MMM YYYY">*/}
      {/*      {article.published}*/}
      {/*    </Moment>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )

  return (
    <Layout>
      <div className={styles.root}>
        <div className={`${styles.bread} ${styles.desktop}`}>
          Pages
          <span>></span>
          <Link to={`/pages/${article.slug}`}>{article.title}</Link>
        </div>
        {mobileTitleBlock}
        {imageBlock}
        {mobileCategory}
        {article.hasShareButtons && mobileShareBlock}
        <div className={styles.scrollable}>
          {article.hasShareButtons && desktopShareBlock}
          <div className={styles.content}>
            {desktopTitleBlock}
            <div className={styles.text}>
              {documentToReactComponents(JSON.parse(article.content.raw), renderOptions)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogTemplate


export const query = graphql`
query PageQuery($id: String) {
  allContentfulBlogPage {
    nodes {
      instagramLink
    }
  }
  article: allContentfulPages(filter: {id: {eq: $id}}) {
    edges {
      node {
        id
        title
        slug
        richTitle {
          raw
        }
        hasShareButtons
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 98
            placeholder: TRACED_SVG
          )
        }
        content {
          raw
        }
      }
    }
  }
}
`

// extracted by mini-css-extract-plugin
export var root = "Pages-module--root--28wdK";
export var bread = "Pages-module--bread--2qkTJ";
export var scrollable = "Pages-module--scrollable--3OGC2";
export var desktop = "Pages-module--desktop--1ZsHj";
export var mobile = "Pages-module--mobile--3jl4-";
export var image = "Pages-module--image--171Jd";
export var title = "Pages-module--title--KTZHn";
export var category = "Pages-module--category--3WDa2";
export var categoryCol = "Pages-module--categoryCol--1Cm81";
export var categoryTitle = "Pages-module--categoryTitle--1Cz-Y";
export var content = "Pages-module--content--25iNf";
export var text = "Pages-module--text--11W6H";